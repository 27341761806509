import { useEffect, useState } from "react";

export const MenuNavForPhone = ({
  transformMobileMenu,
  setTransformMobileMenu,
}) => {
  const [showJoinButton, setShowJoinButton] = useState(false);

  useEffect(() => {
    const eventDate = new Date('2024-10-09T14:45:00');
    const now = new Date();

    if (now >= eventDate) {
      setShowJoinButton(true);
    }

    const intervalId = setInterval(() => {
      const currentTime = new Date();
      if (currentTime >= eventDate) {
        setShowJoinButton(true);
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleCloseMenu = () => {
    setTransformMobileMenu(-100);
    document.body.style.overflow = 'unset';
  };

  return (
    <div
      style={
        {
          transform: `translateX(${transformMobileMenu}%)`,
        }
      }
      className="burger-menu"
    >
      <div className="burger-menu__header">
        <div className="wrapper wrapper--for-menu">
          <div className="logo" />
          <button 
            type="button" 
            className="close-for-smallScreen"
            onClick={handleCloseMenu}
          >
          </button>
        </div>
      </div>
      <div className="wrapper">
        <div className="burger-menu__nav">
          <a
            href="#about"
            className="burger-menu__link"
            onClick={handleCloseMenu}
          >
            Про нас
          </a>
          <a
            href="#program"
            className="burger-menu__link"
            onClick={handleCloseMenu}
          >
            Програма форуму
          </a>
          <a
            href="#manifest"
            className="burger-menu__link"
            onClick={handleCloseMenu}
          >
            Маніфест
          </a>
          {!showJoinButton
            ? (
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSeSr6jYMKUc-Ji1zYhSbBdZp2zLEtwLlX9JxAigCNuEtaVZGQ/viewform"
                className="burger-menu__link"
                onClick={handleCloseMenu}
              >
                Реєстрація
              </a>
            ) : (
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSdgeJcrfQbKPIjZ_mk-wuiPFs9QCfJR2HSyR6xdC02KER1VBQ/viewform"
                className="burger-menu__link"
                onClick={handleCloseMenu}
              >
                Доєднатися
              </a>
          )}
        </div>
      </div>
    </div>
  );
};
