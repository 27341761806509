import React, { useState, useEffect } from "react";

const ManifestSection = () => {
  const [timeRemaining, setTimeRemaining] = useState("");
  const [randomPositions, setRandomPositions] = useState([]);
  const [isEventPassed, setIsEventPassed] = useState(false); 
  const [loading, setLoading] = useState(true);
  const [isTextVisible, setIsTextVisible] = useState(false);
  const eventDate = new Date("October 9, 2024 14:45:00").getTime();

  const generateRandomPositions = () => {
    const positions = [];
    const numberOfManifest = 10;
    for (let i = 0; i < numberOfManifest; i++) {
      const randomX = Math.random() * 80;
      const randomY = Math.random() * 90;
      positions.push({ top: `${randomY + 5}%`, left: `${randomX}%` });
    }
    setRandomPositions(positions);
  };

  useEffect(() => {
    generateRandomPositions();
    const interval = setInterval(generateRandomPositions, 3000); 
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const countdown = setInterval(() => {
      const now = new Date().getTime();
      const distance = eventDate - now;

      if (distance <= 0) {
        clearInterval(countdown);
        setIsEventPassed(true);
      } else {
        const totalMinutes = Math.floor(distance / (1000 * 60)); // загальні хвилини
        const seconds = Math.floor((distance % (1000 * 60)) / 1000); // залишкові секунди

        if (totalMinutes < 60) {
          // Якщо залишається менше 60 хвилин, відображаємо хвилини та секунди
          const formattedSeconds = String(seconds).padStart(2, "0");
          setTimeRemaining(`${totalMinutes}:${formattedSeconds}`);
        } else {
          // Форматуємо години та хвилини
          const totalHours = Math.floor(totalMinutes / 60); 
          const minutes = totalMinutes % 60;
          const formattedMinutes = String(minutes).padStart(2, "0");
          setTimeRemaining(`${totalHours}:${formattedMinutes}`);
        }

        setLoading(false); 
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [eventDate]);

  if (isEventPassed) {
    return (
      <section id="manifest">
        <div className="wrapper">
          <div className="container">
            <div className="background-layer"></div>
            <div className="content-layer--manifest">
              <div className="container--manifest">
                <h2 className="manifest__title">Маніфест Культнаступу</h2>
                <div className="manifest__text">
                  <p>
                    Нинішня війна насамперед є війною за ідентичність, де культура – не лише будівельний матеріал нації, а й могутня зброя.
                  </p>
                  <br/>
                  <p>
                    Українська ідентичність є стрижнем національної безпеки й економічного розвитку країни.
                  </p>
                  <br/>
                  <p>
                    Національний культурний продукт має зміцнювати нашу спільну ідентичність, підвищувати культурний і освітній рівень українців, формувати  відповідальне суспільство зі сталими дієвими інституціями. 
                  </p>
                  <br/>
                  <p>
                    Усвідомлення національними елітами ролі культури і здатність реалізувати дієву культурну політику в час війни це без перебільшення питання самого виживання держави. 
                  </p>
                  <br/>
                  {!isTextVisible && (
                    <button 
                      className="manifest__button"
                      onClick={() => setIsTextVisible(true)}
                    >
                      <div className="manifest__button--icon"></div>
                      Читати повністю
                    </button>
                  )}
                  {isTextVisible && 
                    (
                      <p>
                        Культурно-інформаційний простір є надважливим полем бою, а наступальна культурна політика – необхідною передумовою нашої перемоги.
                        <br/>
                        <br/>
                        Уявлення, що «культура може почекати» в умовах війни за ідентичність є безглуздям, а штучне протиставлення підтримки української культури підтримці армії – явною диверсією ворога. 
                        <br/>
                        <br/>
                        При цьому сама держава-агресор вкладає колосальні ресурси у власну культурно-інформаційну політику. Російські наративи, російська мова, література, музика, кіно не лише мобілізують самих росіян, але й мають величезний руйнівний вплив на весь світ і в першу чергу – на Україну.
                        <br/>
                        <br/>
                        Замість ефективної протидії цьому ворожому впливу і здійснення власної наступальної культурної політики Україна вдається до бездумної «економії» на культурі. Втрати від цього, в тому числі й у грошовому вимірі, незрівнянно більші за «зекономлені» вкладення.
                        <br/>
                        <br/>
                        Така ситуація небезпечна і неприйнятна. Щоб її зміни, потрібні спільні активні дії держави, представників українських культурних індустрій, творців культурного продукту і відповідальних громадян.
                        <br/>
                        <br/>
                        Наша мета – започаткувати системну послідовну державну політику, що забезпечить стабільний розвиток національних культурних індустрій, вироблення і споживання якісного українського культурного продукту в належних обсягах, безповоротне витіснення російського культурного продукту з України.
                        <br/>
                        <br/>
                        Наріжним каменем цієї політики мають стати прозорі і сталі механізми, які стимулюватимуть створення і поширення культурного продукту і при цьому забезпечуватимуть рівні можливості для учасників ринку. Корупційні практики ручного розподілу коштів у галузі культури мають назавжди піти в минуле.
                        <br/>
                        <br/>
                        Держава має всіма доступними інструментами стимулювати авторів і бізнеси, які виробляють і поширюють книги, фільми популярну музику українською мовою.
                        <br/>
                        <br/>
                        Єдиний спосіб не дати Росії знову окупувати наш культурно-інформаційний простір у повоєнний час – чимшвидше заповнити весь цей простір різноформатним україномовним продуктом, створивши нову реальність для споживача і перетворивши її на норму.
                        <br/>
                        <br/>
                        Культнаступ – це докорінна зміна самих підходів до культурної політики. Це активні й усвідомлені спільні дії, спрямовані на результат.
                        <br/>
                        <br/>
                        Культнаступ – це створення державою таких умов, щоб бізнесу було вигідно вкладати кошти у виробництво й поширення якісного українського культурного продукту.
                        <br/>
                        <br/>
                        Культнаступ – це повна імплементація законів про підтримку українського книговидання і книгорозповсюдження і захист книжкового ринку від російського впливу.
                        <br/>
                        <br/>
                        Культнаступ – це перехід до використання дієвих інструментів стимулювання розвитку популярної української музики та її донесення до споживачів, особливо дітей та молоді.
                        <br/>
                        <br/>
                        Культнаступ – це розвиток українського кіно навіть під час війни і особливо під час війни, бо якісний кінематограф є могутнім інструментом формування й об’єднання нації, популяризації України у світі. Це заохочення виробництва і потужної реклами фільмів, максимальний розвиток засобів донесення кінопродукції до споживачів.
                        <br/>
                        <br/>
                        Культнаступ – це особливий акцент на просування всіх видів україномовної продукції, що споживається в інтернеті чи завантажується на гаджети. Україномовна національна й локалізована західна продукція має цілковито витіснити російськомовну.
                        <br/>
                        <br/>
                        Культнаступ – це комунікація на високому державному рівні з ключовими світовими інтернет-платформами й соцмережами задля очищення українського культурно-інформаційного простору від російського вмісту і максимально сприятливих алгоритмів поширення українського.
                        <br/>
                        <br/>
                        Культнаступ – це належне фінансування та якісне управління культурною дипломатією, активне сприяння з боку держави просуванню українського продукту на зарубіжні ринки, українська присутність у європейському і світовому культурному просторі, представленість на міжнародних фестивалях, виставках та ярмарках, цілеспрямоване формування позитивного образу України. Від цього напряму залежить сприйняття нас у світі, а отже і рівень політичної, економічної та військової підтримки.
                        <br/>
                        <br/>
                        Ми об’єднуємо зусилля для створення міцних правових, політичних, економічних, культурних підвалин необхідних змін. 
                        <br/>
                        <br/>
                        Важливість наступальної культурної політики має стати аксіомою.
                        <br/>
                        <br/>
                        Для успіху такої політики Україна має всі можливості, варто лише їх усвідомити і перейти до рішучих та послідовних дій.
                      </p>
                  )}
                </div>
              </div>
              <div className="program__block-action-link">
                <a 
                  href="https://docs.google.com/forms/d/e/1FAIpQLSdgeJcrfQbKPIjZ_mk-wuiPFs9QCfJR2HSyR6xdC02KER1VBQ/viewform" 
                  className="bttn bttn--primary"
                >
                  <div className="bttn--icon"/>
                  ПІДПИСАТИ МАНІФЕСТ
                </a>
              </div>
              {/*
              <div className="container--manifest">
                <h2 className="manifest__title-2">МАНІФЕСТ КУЛЬТНАСТУПУ ПІДПИСАЛИ</h2>

              </div>
              <div className="program__block-action-link">
                <a 
                  href="https://docs.google.com/forms/d/e/1FAIpQLSdgeJcrfQbKPIjZ_mk-wuiPFs9QCfJR2HSyR6xdC02KER1VBQ/viewform" 
                  className="bttn bttn--primary"
                >
                  <div className="bttn--icon"/>
                  ПІДПИСАТИ МАНІФЕСТ
                </a>
              </div>
              */}
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section id="manifest">
      <div className="wrapper">
        <div className="container">
          <div className="background-layer"></div>
          <div className="content-layer content-layer--grey" style={{ position: "relative" }}>
            {randomPositions.map((pos, index) => (
              <div
                key={index}
                className="manifest__word"
                style={{
                  position: "absolute",
                  top: pos.top,
                  left: pos.left,
                  animationDelay: `${index * 0.5}s`,
                }}
              >
                МАНІФЕСТ
              </div>
            ))}
            {randomPositions.map((pos, index) => (
              <div
                key={index}
                className="manifest__word-delay"
                style={{
                  position: "absolute",
                  top: pos.top,
                  left: pos.left,
                  animationDelay: `${index * 0.5}s`,
                }}
              >
                МАНІФЕСТ
              </div>
            ))}

            <div className="manifest__waiting" style={{ textAlign: "center" }}>
              <div className="manifest__waiting--text">
                З'явиться через
              </div>
              {loading ? (
                <div className="lds-ring" style={{ margin: "20px auto" }}>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                <div className="manifest__waiting--timer">
                  {timeRemaining}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ManifestSection;
